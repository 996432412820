import axios from "axios";
import {
  GET_CONVERT1,
  GET_CONVERT2,
  GET_CONVERT3,
  GET_CONVERT4,
  GET_CONVERT5,
} from "./types";

import { endpoint, prodEndpoint } from "../config";

const URL = process.env.NODE_ENV === "development" ? endpoint : prodEndpoint;

const convert1 = (data) => {
  // console.log("Convert1Data", data);
  return async (dispatch) => {
    try {
      await axios.post(`${URL}api/v1/tx/prove`, data).then((response) => {
        dispatch({
          type: GET_CONVERT1,
          payload: response.data,
        });
      });
    } catch (error) {
      console.log(error);
    }
  };
};

const convert2 = (data) => {
  // console.log("Convert2Data", data);
  return async (dispatch) => {
    try {
      await axios.post(`${URL}api/v1/tx/prove`, data).then((response) => {
        dispatch({
          type: GET_CONVERT2,
          payload: response.data,
        });
      });
    } catch (error) {
      // console.log(error);
    }
  };
};
const convert3 = (data) => {
  // console.log("Convert3Data", data);
  return async (dispatch) => {
    try {
      await axios.post(`${URL}api/v1/tx/prove`, data).then((response) => {
        // console.log("Convert3Data", response.data);

        dispatch({
          type: GET_CONVERT3,
          payload: response.data,
        });
      });
    } catch (error) {
      // console.log(error);
    }
  };
};

const convert4 = (data) => {
  // console.log("Convert4Data", data);
  return async (dispatch) => {
    try {
      await axios.post(`${URL}api/v1/tx/prove`, data).then((response) => {
        dispatch({
          type: GET_CONVERT4,
          payload: response.data,
        });
      });
    } catch (error) {
      // console.log(error);
    }
  };
};

const convert5 = (data) => {
  return async (dispatch) => {
    try {
      await axios.post(`${URL}api/v1/tx/prove`, data).then((response) => {
        dispatch({
          type: GET_CONVERT5,
          payload: response.data,
        });
      });
    } catch (error) {
      // console.log(error);
    }
  };
};

export { convert2, convert1, convert3, convert4, convert5 };

import React, { useState, useRef, useEffect, useCallback } from "react";
import "./Banner.css";
import CountDown from "./count-down/CountDown";
import { AiFillCheckCircle } from "react-icons/ai";
import moment from "moment";

import ReactCanvasConfetti from "react-canvas-confetti";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

const canvasStyles = {
  position: "fixed",
  pointerEvents: "none",
  width: "70%",
  height: "60%",
  top: 0,
  left: 0,
};
const Banner = (props) => {
  const {
    alltrans,
    seconds,
    minutes,
    hours,
    flashUpdate,
    date3,
    date4,

    date1,
    date2,
    date7,
    date1_1,
    nowDate,
    updateB,
  } = props;
  // console.log("Banner Trans Result", alltrans);
  var [stable, setStable] = useState(1);
  const refAnimationInstance = useRef(null);

  const getInstance = useCallback((instance) => {
    refAnimationInstance.current = instance;
  }, []);

  const makeShot = useCallback((particleRatio, opts) => {
    refAnimationInstance.current &&
      refAnimationInstance.current({
        ...opts,
        origin: { y: 0.7 },
        particleCount: Math.floor(200 * particleRatio),
      });
  }, []);

  const fire = useCallback(() => {
    makeShot(0.25, {
      spread: 26,
      startVelocity: 55,
    });

    makeShot(0.2, {
      spread: 60,
    });

    makeShot(0.35, {
      spread: 100,
      decay: 1.5,
      scalar: 0.8,
    });

    makeShot(0.1, {
      spread: 120,
      startVelocity: 25,
      decay: 1.5,
      scalar: 1.2,
    });

    makeShot(1.5, {
      spread: 120,
      startVelocity: 45,
    });
  }, [makeShot]);

  useEffect(() => {
    if (flashUpdate === 1) {
      fire();
      // console.log("FlashUpdate", flashUpdate);
    }
  }, [flashUpdate]);

  // console.log("UpdateB", updateB);

  useEffect(() => {
    if (alltrans) {
      setTimeout(() => {
        setStable(0);
      }, 5000);
      setStable(1);
    }
  }, [alltrans]);

  // console.log("Now Date", "Update");

  let dateC1634 = moment(
    new Date(`${moment(moment(new Date())).format("MM/DD/YYYY")} 16:34:00`)
  );
  let dateC1630 = moment(
    new Date(`${moment(moment(new Date())).format("MM/DD/YYYY")} 16:30:00`)
  );

  let dateC1214 = moment(
    new Date(`${moment(moment(new Date())).format("MM/DD/YYYY")} 12:01:04`)
  );
  let dateC121 = moment(
    new Date(`${moment(moment(new Date())).format("MM/DD/YYYY")} 12:01:00`)
  );

  let dateC930 = moment(
    new Date(`${moment(moment(new Date())).format("MM/DD/YYYY")} 9:30:04`)
  );
  let dateC934 = moment(
    new Date(`${moment(moment(new Date())).format("MM/DD/YYYY")} 9:30:00`)
  );

  let dateC1404 = moment(
    new Date(`${moment(moment(new Date())).format("MM/DD/YYYY")} 14:00:04`)
  );
  let dateC1400 = moment(
    new Date(`${moment(moment(new Date())).format("MM/DD/YYYY")} 14:00:00`)
  );

  //home initila null
  let dateC001 = moment(
    new Date(`${moment(moment(new Date())).format("MM/DD/YYYY")} 00:00:01`)
  );
  let dateC929 = moment(
    new Date(`${moment(moment(new Date())).format("MM/DD/YYYY")} 9:30:00`)
  );

  let dateC9304 = moment(
    new Date(`${moment(moment(new Date())).format("MM/DD/YYYY")} 9:30:04`)
  );
  let dateC931 = moment(
    new Date(`${moment(moment(new Date())).format("MM/DD/YYYY")} 9:31:00`)
  );
  let dateC1204 = moment(
    new Date(`${moment(moment(new Date())).format("MM/DD/YYYY")} 12:01:04`)
  );
  let dateC1402 = moment(
    new Date(`${moment(moment(new Date())).format("MM/DD/YYYY")} 14:02:00`)
  );
  let dateC16304 = moment(
    new Date(`${moment(moment(new Date())).format("MM/DD/YYYY")} 16:30:04`)
  );
  let dateC2359 = moment(
    new Date(`${moment(moment(new Date())).format("MM/DD/YYYY")} 23:59:59`)
  );

  let updateTime930_931 = nowDate > dateC9304 && nowDate < dateC931;
  let updateTime1201_1402 = nowDate > dateC1204 && nowDate < dateC1402;
  let updateTime1630_2359 = nowDate > dateC16304 && nowDate < dateC2359;

  return (
    <section>
      <ReactCanvasConfetti refConfetti={getInstance} style={canvasStyles} />
      <div className="text-center">
        <div className="flex flex-col justify-center items-center  gap-4  pt-10  ">
          {" "}
          <div>
            {![""].some((e) => moment(new Date()).format("ddd") === e) ? (
              updateTime930_931 ||
                updateTime1201_1402 ||
                updateTime1630_2359 ? (
                updateB === 0 ? (
                  ""
                ) : (
                  <h1 className=" text-4xl font-display">Latest Result</h1>
                )
              ) : (
                ""
              )
            ) : (
              ""
            )}

            <div style={{ height: "12rem" }}>
              <h2
                className={`text-orange-500 font-display font-bold text-9xl mt-4  ${!(nowDate > dateC001 && nowDate < dateC929)
                  ? updateTime930_931 ||
                    updateTime1201_1402 ||
                    updateTime1630_2359
                    ? ""
                    : stable
                      ? ""
                      : "numAnimate"
                  : ""
                  // updateB === 0 ? (stable ? "" : "numAnimate") : ""
                  }`}
              >
                {![""].some((e) => moment(new Date()).format("ddd") === e)
                  ? !(nowDate > dateC001 && nowDate < dateC929)
                    ? alltrans?.odds.toString().length === 1
                      ? `0${alltrans?.odds}`
                      : alltrans?.odds
                    : "- -"
                  : "- -"}
              </h2>
            </div>
            {![""].some((e) => moment(new Date()).format("ddd") === e)
              ? !(nowDate > dateC001 && nowDate < dateC929) &&
              (updateTime930_931 ||
                updateTime1201_1402 ||
                updateTime1630_2359 ? (
                <div className="text-[14px] ">
                  <div className="flex justify-center justify-items-center">
                    <AiFillCheckCircle className="text-green-400 mr-1 mt-1" />
                    <span className="text-[#464646]">
                      Updated -{" "}
                      {moment(alltrans?.txt_date).format("DD-MM-YYYY")}
                    </span>
                  </div>
                  <p className="text-[#FB9148]">
                    &nbsp;
                    {nowDate >
                      moment(
                        new Date(
                          `${moment(moment(new Date())).format(
                            "MM/DD/YYYY"
                          )} 12:01:06`
                        )
                      ) &&
                      nowDate <
                      moment(
                        new Date(
                          `${moment(moment(new Date())).format(
                            "MM/DD/YYYY"
                          )} 14:00:00`
                        )
                      )
                      ? "12:01 PM"
                      : nowDate > date1 && nowDate < date2
                        ? "4:30 PM"
                        : ""}
                  </p>
                </div>
              ) : stable ? (
                <>
                  <p className="text-[#FB9148]">
                    {" "}
                    {
                      moment(alltrans?.txt_date).format("lll") // Jan 22, 2022 12:23 PM
                    }
                    &nbsp;
                  </p>
                </>
              ) : (
                <p className="text-[#FB9148]">Drawing....</p>
              ))
              : ""}
            {/* 
              (updateB === 0 ? (
                stable ? (
                  <>
                    <p className="text-orange-700">
                      {" "}
                      {
                        moment(alltrans?.txt_date).format("lll") // Jan 22, 2022 12:23 PM
                      }
                      &nbsp;
                    </p>
                  </>
                ) : (
                  <p className="text-yellow-700">Drawing....</p>
                )
              ) : (
                <>
                  <div className="flex justify-center">
                    <AiFillCheckCircle className="text-green-400 mr-1" />
                    <span className="text-orange-700">Updated</span>
                  </div>
                  <p className="text-orange-700">
                    {" "}
                    {
                      moment(alltrans?.txt_date).format("ll") // Jan 22, 2022 12:23 PM
                    }
                    &nbsp;
                    {nowDate >
                      moment(
                        new Date(
                          `${moment(moment(new Date())).format(
                            "MM/DD/YYYY"
                          )} 12:01:06`
                        )
                      ) &&
                    nowDate <
                      moment(
                        new Date(
                          `${moment(moment(new Date())).format(
                            "MM/DD/YYYY"
                          )} 14:00:00`
                        )
                      )
                      ? "12:01 PM"
                      : nowDate > date1 && nowDate < date2
                      ? "4:30 PM"
                      : ""}
                  </p>
                </>
              ))} */}
          </div>
          <div className="pt-10">
            <span className="text-[hsl(0, 0%, 27%)] text-[14px] font-display">
              Countdown To Drawings
            </span>
            <br />
            <br />
            {(nowDate < dateC1634 && nowDate > dateC1630) ||
              (nowDate < dateC1214 && nowDate > dateC121) ||
              (nowDate < dateC934 && nowDate > dateC930) ||
              (nowDate < dateC1404 && nowDate > dateC1400) ? (
              <CountDown
                alltrans={alltrans}
                hours={0}
                minutes={0}
                seconds={0}
              />
            ) : (
              <CountDown
                alltrans={alltrans}
                hours={hours}
                minutes={minutes}
                seconds={seconds}
              />
            )}
            {/* <CountDown
              alltrans={alltrans}
              hours={hours}
              minutes={minutes}
              seconds={seconds}
            /> */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Banner;

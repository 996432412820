import React, { useState, useRef, useEffect, useCallback } from "react";
import { AiOutlineClockCircle } from "react-icons/ai";
import { FaCheckCircle } from "react-icons/fa";
import moment from "moment";
import ReactCanvasConfetti from "react-canvas-confetti";

const canvasStyles = {
  position: "fixed",
  pointerEvents: "none",
  width: "70%",
  height: "60%",
  top: 0,
  left: "15%",
};

const LiveResult = (props) => {
  const {
    alltrans,
    date8,
    date3,
    date10,
    nowDate,
    date1,
    updateB,
    flashUpdate,
  } = props;

  var [stable, setStable] = useState(0);
  const refAnimationInstance = useRef(null);

  const getInstance = useCallback((instance) => {
    refAnimationInstance.current = instance;
  }, []);

  const makeShot = useCallback((particleRatio, opts) => {
    refAnimationInstance.current &&
      refAnimationInstance.current({
        ...opts,
        origin: { y: 0.7 },
        particleCount: Math.floor(200 * particleRatio),
      });
  }, []);

  const fire = useCallback(() => {
    makeShot(0.25, {
      spread: 26,
      startVelocity: 55,
    });

    makeShot(0.2, {
      spread: 60,
    });

    makeShot(0.35, {
      spread: 100,
      decay: 1.5,
      scalar: 0.8,
    });

    makeShot(0.1, {
      spread: 120,
      startVelocity: 25,
      decay: 1.5,
      scalar: 1.2,
    });

    makeShot(1.5, {
      spread: 120,
      startVelocity: 45,
    });
  }, [makeShot]);
  useEffect(() => {
    if (flashUpdate === 1) {
      fire();
      // console.log("FlashUpdate", flashUpdate);
    }
  }, [flashUpdate]);

  useEffect(() => {
    if (alltrans) {
      setTimeout(() => {
        setStable(0);
      }, 5000);
      setStable(1);
    }
  }, [alltrans]);

  let dateC9304 = moment(
    new Date(`${moment(moment(new Date())).format("MM/DD/YYYY")} 9:30:04`)
  );
  let dateC931 = moment(
    new Date(`${moment(moment(new Date())).format("MM/DD/YYYY")} 9:31:00`)
  );
  let dateC1404 = moment(
    new Date(`${moment(moment(new Date())).format("MM/DD/YYYY")} 14:00:04`)
  );
  let dateC1410 = moment(
    new Date(`${moment(moment(new Date())).format("MM/DD/YYYY")} 14:01:00`)
  );
  let dateC1204 = moment(
    new Date(`${moment(moment(new Date())).format("MM/DD/YYYY")} 12:01:04`)
  );
  let dateC1230 = moment(
    new Date(`${moment(moment(new Date())).format("MM/DD/YYYY")} 12:30:00`)
  );
  let dateC16304 = moment(
    new Date(`${moment(moment(new Date())).format("MM/DD/YYYY")} 16:30:04`)
  );
  let dateC1700 = moment(
    new Date(`${moment(moment(new Date())).format("MM/DD/YYYY")} 17:00:00`)
  );
  let updateTime930_931 = nowDate > dateC9304 && nowDate < dateC931;
  let updateTime1404_1410 = nowDate > dateC1404 && nowDate < dateC1410;
  let updateTime1201_1230 = nowDate > dateC1204 && nowDate < dateC1230;
  let updateTime1630_2359 = nowDate > dateC16304 && nowDate < dateC1700;
  return (
    <section className="mt-9">
      <ReactCanvasConfetti refConfetti={getInstance} style={canvasStyles} />

      <div
        className="text-center pointer-events-none "
        style={{ height: "18rem" }}
      >
        <button className="md:text-right btn btn-sm btn-danger cursor-none text-gray-50 ml-60">
          Live
        </button>
        <h1
          className={`text-orange-500 text-9xl mt-8 ${![""].some((e) => moment(new Date()).format("ddd") === e)
            ? updateTime930_931 ||
              updateTime1201_1230 ||
              updateTime1630_2359 ||
              updateTime1404_1410
              ? ""
              : stable
                ? ""
                : "numAnimate"
            : ""
            } font-bold`}
        >
          {alltrans?.odds.toString().length === 1
            ? `0${alltrans?.odds}`
            : alltrans?.odds
              ? alltrans?.odds
              : "- -"}
        </h1>
        <br />
        <center>
          {![""].some((e) => moment(new Date()).format("ddd") === e) ? (
            !(
              updateTime930_931 ||
              updateTime1201_1230 ||
              updateTime1630_2359 ||
              updateTime1404_1410
            ) ? (
              stable ? (
                <>
                  <span className="text-orange-500 text-1xl mt-9">
                    {moment(alltrans?.timestamp).format("lll")}
                  </span>
                </>
              ) : (
                <>
                  <span className="text-orange-500">Drawing...</span>
                </>
              )
            ) : (
              <>
                <FaCheckCircle className="text-green-500 text-3xl mb-2" />
                <span className="text-red-500 text-1xl mt-9">
                  {moment(alltrans?.timestamp).format("ll")}
                  &nbsp;
                  {nowDate > date3 && nowDate < date8
                    ? "12:01 PM"
                    : nowDate > date1 && nowDate < date10
                      ? "4:30 PM"
                      : ""}
                </span>
              </>
            )
          ) : (
            <span className="text-orange-500">Close</span>
          )}

          {/* {(nowDate > date3 && nowDate < date8) ||
          (nowDate > date1 && nowDate < date10) ? (
            <>
              <FaCheckCircle className="text-green-500 text-3xl mb-2" />
              <span className="text-orange-500 text-1xl mt-9">
                {moment(alltrans?.timestamp).format("ll")}
                &nbsp;
                {nowDate > date3 && nowDate < date8
                  ? "12:01 PM"
                  : nowDate > date1 && nowDate < date10
                  ? "4:30 PM"
                  : ""}
              </span>
            </>
          ) : stable === 0 ? (
            <>
              <span className="text-orange-500">Drawing...</span>
            </>
          ) : (
            <>
              <span className="text-orange-500 text-1xl mt-9">
                {moment(alltrans?.timestamp).format("lll")}
              </span>
            </>
          )} */}
        </center>

        <br />
        <br />
      </div>
    </section>
  );
};

export default LiveResult;

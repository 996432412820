import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./components/home/Home";
import Pusher from "pusher-js";
import LivePage from "./components/live/LivePage";
import Proof from "./components/proof/Proof";
import Result from "./components/result/Result";
import { getLastTx } from "./actions/trans1Action";
import Moment from "moment";
import History from "./components/history/History";

import { PUSHER_API, PUSHER_API_PROD } from "./config";
import About from "./components/about-us/About";
import Download from "./components/download/Download";

const PUSHER_KEY =
  process.env.NODE_ENV === "development" ? PUSHER_API : PUSHER_API_PROD;

const RouteAll = (props) => {
  const { socket } = props;
  const [lastData30, setLastData30] = useState(null);
  const [lastData430, setLastData430] = useState(null);
  const [result430, setResult430] = useState(null);
  const [txData30, setTxData30] = useState(null);
  const [txData120, setTxData120] = useState(null);
  const [txData300, setTxData300] = useState(null);
  const [txData12_01, setTxData12_01] = useState(null);
  const [txData14_00, setTxData14_00] = useState(null);
  const [txData4_30, setTxData4_30] = useState(undefined);
  const [txData9_30, setTxData9_30] = useState(null);

  const [txData30Live, setTxData30Live] = useState(null);
  const [txData120Live, setTxData120Live] = useState(null);
  const [txData300Live, setTxData300Live] = useState(null);
  const [txData12_01Live, setTxData12_01Live] = useState(null);
  const [txData14_00Live, setTxData14_00Live] = useState(null);
  const [txData4_30Live, setTxData4_30Live] = useState(null);

  const [txData9_30Live, setTxData9_30Live] = useState(null);
  const [bannerTrans, setBannerTrans] = useState(null);
  const [bannerTransLive, setBannerTransLive] = useState(null);

  let date1211 = Moment(
    new Date(`${Moment(Moment(new Date())).format("MM/DD/YYYY")} 12:01:00`)
  );

  let date1430 = Moment(
    new Date(`${Moment(Moment(new Date())).format("MM/DD/YYYY")} 14:00:00`)
  );
  let date1630 = Moment(
    new Date(`${Moment(Moment(new Date())).format("MM/DD/YYYY")} 16:30:01`)
  );

  let date235959 = Moment(
    new Date(`${Moment(Moment(new Date())).format("MM/DD/YYYY")} 23:59:59`)
  );
  let date930 = Moment(
    new Date(`${Moment(Moment(new Date())).format("MM/DD/YYYY")} 9:30:01`)
  );

  let date931 = Moment(
    new Date(`${Moment(Moment(new Date())).format("MM/DD/YYYY")} 9:31:01`)
  );
  let date1401 = Moment(
    new Date(`${Moment(Moment(new Date())).format("MM/DD/YYYY")} 14:00:01`)
  );

  let date1402 = Moment(
    new Date(`${Moment(Moment(new Date())).format("MM/DD/YYYY")} 14:01:01`)
  );
  let nowDate = Moment(new Date());

  const [updateB, setUpdateB] = useState(
    (nowDate < date1430 && nowDate > date1211) ||
      (nowDate < date235959 && nowDate > date1630) ||
      (nowDate < date931 && nowDate > date930) ||
      (nowDate < date1402 && nowDate > date1401)
      ? 1
      : 0
  );

  const [flashUpdate, setFlashUpdate] = useState(
    (nowDate < date1430 && nowDate > date1211) ||
      (nowDate < date235959 && nowDate > date1630)
      ? 0
      : null
  );

  let date3 = Moment(
    new Date(`${Moment(Moment(new Date())).format("MM/DD/YYYY")} 12:01`)
  );

  let date1 = Moment(
    new Date(`${Moment(Moment(new Date())).format("MM/DD/YYYY")} 16:30`)
  );

  let date5 = Moment(
    new Date(`${Moment(Moment(new Date())).format("MM/DD/YYYY")} 00:00:01`)
  );

  // console.log("Log", txData30);

  useEffect(() => {
    // var pusher = new Pusher(PUSHER_KEY, {
    //   cluster: "ap1",
    // });
    // const channel = pusher.subscribe("tx");
    //test
    //holding 12_45 --->

    // if (Moment(new Date()).format("ddd") !== "Sun") {
    if (![""].some((e) => Moment(new Date()).format("ddd") === e)) {
      socket.on("tx30sec_sk", (data) => {
        //home
        let nowDate = Moment(new Date());

        let date0001_931 =
          nowDate >
          Moment(new Date(`${Moment().format("MM/DD/YYYY")} 00:00:01`)) &&
          nowDate <
          Moment(new Date(`${Moment().format("MM/DD/YYYY")} 09:31:00`));
        let date12045_1401 =
          nowDate >
          Moment(new Date(`${Moment().format("MM/DD/YYYY")} 12:00:45`)) &&
          nowDate <
          Moment(new Date(`${Moment().format("MM/DD/YYYY")} 14:01:00`));
        let date162945_23_59_59 =
          nowDate >
          Moment(new Date(`${Moment().format("MM/DD/YYYY")} 16:29:45`)) &&
          nowDate <
          Moment(new Date(`${Moment().format("MM/DD/YYYY")} 23:59:59`));

        let combineVar = date0001_931 || date12045_1401 || date162945_23_59_59;
        if (date162945_23_59_59) {
          // console.log("1630 True Tx30");
        }

        if (!combineVar) {
          setTxData30(data?.result);
          // console.log("Should not enter");

          setBannerTrans(data?.result);
        }

        // live
        let date0001_931Live =
          nowDate >
          Moment(new Date(`${Moment().format("MM/DD/YYYY")} 09:29:45`)) &&
          nowDate <
          Moment(new Date(`${Moment().format("MM/DD/YYYY")} 09:31:00`));
        let date12045_1401Live =
          nowDate >
          Moment(new Date(`${Moment().format("MM/DD/YYYY")} 12:00:45`)) &&
          nowDate <
          Moment(new Date(`${Moment().format("MM/DD/YYYY")} 12:30:00`));
        let date135945_1401Live =
          nowDate >
          Moment(new Date(`${Moment().format("MM/DD/YYYY")} 13:59:45`)) &&
          nowDate <
          Moment(new Date(`${Moment().format("MM/DD/YYYY")} 14:01:00`));
        let date162945_23_59_59Live =
          nowDate >
          Moment(new Date(`${Moment().format("MM/DD/YYYY")} 16:29:45`)) &&
          nowDate <
          Moment(new Date(`${Moment().format("MM/DD/YYYY")} 17:00:00`));

        let combineVarLive =
          date0001_931Live ||
          date12045_1401Live ||
          date135945_1401Live ||
          date162945_23_59_59Live;
        if (date162945_23_59_59Live) {
          // console.log("1630 True Tx30 Live");
        }

        if (!combineVarLive) {
          setTxData30Live(data?.result);
          // console.log("Should not enter");

          setBannerTransLive(data?.result);
        }
        // live
      });
      socket.on("tx1201_sk", (data) => {
        setUpdateB(1);
        setFlashUpdate(1);
        setTxData12_01(data?.result);
        setBannerTrans(data?.result);
        ////
        setTxData12_01Live(data?.result);
        setBannerTransLive(data?.result);
      });
      socket.on("tx1630_sk", (data) => {
        setUpdateB(1);
        setFlashUpdate(1);

        setTxData4_30(data?.result);
        setBannerTrans(data?.result);
        ////
        setTxData4_30Live(data?.result);
        setBannerTransLive(data?.result);
      });
      socket.on("tx0930_sk", (data) => {
        setUpdateB(1);
        // setFlashUpdate(1);
        setTxData9_30(data?.result);
        setBannerTrans(data?.result);
        ///
        setTxData9_30Live(data?.result);
        setBannerTransLive(data?.result);
      });
      socket.on("tx1400_sk", (data) => {
        setUpdateB(1);
        setTxData14_00(data?.result);
        setBannerTrans(data?.result);
        //
        setTxData14_00Live(data?.result);
        setBannerTransLive(data?.result);
      });
    }
    //socket

    //socket

    //Pusher
    //
    // channel.bind("tx_30", (data) => {
    //   //home

    //   let nowDate = Moment(new Date());

    //   let date0001_931 =
    //     nowDate >
    //       Moment(new Date(`${Moment().format("MM/DD/YYYY")} 00:00:01`)) &&
    //     nowDate < Moment(new Date(`${Moment().format("MM/DD/YYYY")} 09:31:00`));
    //   let date12045_1401 =
    //     nowDate >
    //       Moment(new Date(`${Moment().format("MM/DD/YYYY")} 12:00:45`)) &&
    //     nowDate < Moment(new Date(`${Moment().format("MM/DD/YYYY")} 14:01:00`));
    //   let date162945_23_59_59 =
    //     nowDate >
    //       Moment(new Date(`${Moment().format("MM/DD/YYYY")} 16:29:45`)) &&
    //     nowDate < Moment(new Date(`${Moment().format("MM/DD/YYYY")} 23:59:59`));

    //   let combineVar = date0001_931 || date12045_1401 || date162945_23_59_59;
    //   if (date162945_23_59_59) {
    //     console.log("1630 True Tx30");
    //   }

    //   if (!combineVar) {
    //     setTxData30(data?.result);
    //     console.log("Should not enter");

    //     setBannerTrans(data?.result);
    //   }

    //   // live
    //   let date0001_931Live =
    //     nowDate >
    //       Moment(new Date(`${Moment().format("MM/DD/YYYY")} 09:29:45`)) &&
    //     nowDate < Moment(new Date(`${Moment().format("MM/DD/YYYY")} 09:31:00`));
    //   let date12045_1401Live =
    //     nowDate >
    //       Moment(new Date(`${Moment().format("MM/DD/YYYY")} 12:00:45`)) &&
    //     nowDate < Moment(new Date(`${Moment().format("MM/DD/YYYY")} 12:30:00`));
    //   let date135945_1401Live =
    //     nowDate >
    //       Moment(new Date(`${Moment().format("MM/DD/YYYY")} 13:59:45`)) &&
    //     nowDate < Moment(new Date(`${Moment().format("MM/DD/YYYY")} 14:01:00`));
    //   let date162945_23_59_59Live =
    //     nowDate >
    //       Moment(new Date(`${Moment().format("MM/DD/YYYY")} 16:29:45`)) &&
    //     nowDate < Moment(new Date(`${Moment().format("MM/DD/YYYY")} 17:00:00`));

    //   let combineVarLive =
    //     date0001_931Live ||
    //     date12045_1401Live ||
    //     date135945_1401Live ||
    //     date162945_23_59_59Live;
    //   if (date162945_23_59_59Live) {
    //     console.log("1630 True Tx30 Live");
    //   }

    //   if (!combineVarLive) {
    //     setTxData30Live(data?.result);
    //     console.log("Should not enter");

    //     setBannerTransLive(data?.result);
    //   }
    //   // live
    // });
    // channel.bind("tx_1630", (data) => {
    //   setUpdateB(1);
    //   setFlashUpdate(1);

    //   setTxData4_30(data?.result);
    //   setBannerTrans(data?.result);
    //   ////
    //   setTxData4_30Live(data?.result);
    //   setBannerTransLive(data?.result);
    // });
    // channel.bind("tx_1201", (data) => {
    //   setUpdateB(1);
    //   setFlashUpdate(1);
    //   setTxData12_01(data?.result);
    //   setBannerTrans(data?.result);
    //   ////
    //   setTxData12_01Live(data?.result);
    //   setBannerTransLive(data?.result);
    // });

    // channel.bind("tx_1400", (data) => {
    //   setUpdateB(1);
    //   setTxData14_00(data?.result);
    //   setBannerTrans(data?.result);
    //   //
    //   setTxData14_00Live(data?.result);
    //   setBannerTransLive(data?.result);
    // });

    // channel.bind("tx_0930", (data) => {
    //   setUpdateB(1);
    //   // setFlashUpdate(1);
    //   setTxData9_30(data?.result);
    //   setBannerTrans(data?.result);
    //   ///
    //   setTxData9_30Live(data?.result);
    //   setBannerTransLive(data?.result);
    // });

    // });
    //pusher
  }, []);

  useEffect(() => {
    if (![""].some((e) => Moment(new Date()).format("ddd") === e)) {
      getTwoTimes();
    }
  }, []);
  const getTwoTimes = async () => {
    const result30 = await getLastTx({
      tx_type: "BTC_30",
    });

    const result930 = await getLastTx({
      tx_type: "BTC_2D",
      date_type: "0930",
    });

    //9:30

    const result1201 = await getLastTx({
      tx_type: "BTC_2D",
      date_type: "1201",
    });

    const result1400 = await getLastTx({
      tx_type: "BTC_2D",
      date_type: "1400",
    });

    const result1630 = await getLastTx({
      tx_type: "BTC_2D",
      date_type: "1630",
    });
    console.log("result1630DDD", result1630);

    let nowDate = Moment(new Date());
    //Home
    let date0001_931 =
      nowDate > Moment(new Date(`${Moment().format("MM/DD/YYYY")} 00:00:01`)) &&
      nowDate < Moment(new Date(`${Moment().format("MM/DD/YYYY")} 09:31:00`));
    let date12045_1401 =
      nowDate > Moment(new Date(`${Moment().format("MM/DD/YYYY")} 12:00:45`)) &&
      nowDate < Moment(new Date(`${Moment().format("MM/DD/YYYY")} 14:01:00`));
    let date162945_23_59_59 =
      nowDate > Moment(new Date(`${Moment().format("MM/DD/YYYY")} 16:29:45`)) &&
      nowDate < Moment(new Date(`${Moment().format("MM/DD/YYYY")} 23:59:59`));

    let combineVar = date0001_931 || date12045_1401 || date162945_23_59_59;

    if (date162945_23_59_59) {
      // console.log("1630 True Reload");
    }

    if (!combineVar) {
      setTxData30(result30);

      // console.log("Should not enter");
      setBannerTrans(result30);
    }

    let date930_931 =
      nowDate > Moment(new Date(`${Moment().format("MM/DD/YYYY")} 09:30:01`)) &&
      nowDate < Moment(new Date(`${Moment().format("MM/DD/YYYY")} 09:31:00`));
    let date930_235959 =
      nowDate > Moment(new Date(`${Moment().format("MM/DD/YYYY")} 9:30:00`)) &&
      nowDate < Moment(new Date(`${Moment().format("MM/DD/YYYY")} 23:59:59`));

    if (date930_931) {
      setBannerTrans(result930);
    }
    if (date930_235959) {
      setTxData9_30(result930);
    }

    ///////
    let date1201_135959 =
      nowDate > Moment(new Date(`${Moment().format("MM/DD/YYYY")} 12:01:00`)) &&
      nowDate < Moment(new Date(`${Moment().format("MM/DD/YYYY")} 13:59:59`));

    let date1201_235959 =
      nowDate > Moment(new Date(`${Moment().format("MM/DD/YYYY")} 12:01:00`)) &&
      nowDate < Moment(new Date(`${Moment().format("MM/DD/YYYY")} 23:59:59`));

    if (date1201_135959) {
      setBannerTrans(result1201);
    }
    if (date1201_235959) {
      setTxData12_01(result1201);
    }

    ///////
    let date1401_1410 =
      nowDate > Moment(new Date(`${Moment().format("MM/DD/YYYY")} 14:00:01`)) &&
      nowDate < Moment(new Date(`${Moment().format("MM/DD/YYYY")} 14:01:00`));
    let date1401_235959 =
      nowDate > Moment(new Date(`${Moment().format("MM/DD/YYYY")} 14:00:01`)) &&
      nowDate < Moment(new Date(`${Moment().format("MM/DD/YYYY")} 23:59:59`));

    if (date1401_1410) {
      setBannerTrans(result1400);
    }
    if (date1401_235959) {
      setTxData14_00(result1400);
    }

    ////////

    let date16301_235959 =
      nowDate > Moment(new Date(`${Moment().format("MM/DD/YYYY")} 16:30:01`)) &&
      nowDate < Moment(new Date(`${Moment().format("MM/DD/YYYY")} 23:59:59`));

    if (date16301_235959) {
      setTxData4_30(result1630);
      setBannerTrans(result1630);
    }

    //Live

    let date0001_931Live =
      nowDate > Moment(new Date(`${Moment().format("MM/DD/YYYY")} 09:29:45`)) &&
      nowDate < Moment(new Date(`${Moment().format("MM/DD/YYYY")} 09:31:00`));
    let date12045_1401Live =
      nowDate > Moment(new Date(`${Moment().format("MM/DD/YYYY")} 12:00:45`)) &&
      nowDate < Moment(new Date(`${Moment().format("MM/DD/YYYY")} 12:30:00`));
    let date135945_1401Live =
      nowDate > Moment(new Date(`${Moment().format("MM/DD/YYYY")} 13:59:45`)) &&
      nowDate < Moment(new Date(`${Moment().format("MM/DD/YYYY")} 14:01:00`));
    let date162945_23_59_59Live =
      nowDate > Moment(new Date(`${Moment().format("MM/DD/YYYY")} 16:29:45`)) &&
      nowDate < Moment(new Date(`${Moment().format("MM/DD/YYYY")} 17:00:00`));

    let combineVarLive =
      date0001_931Live ||
      date12045_1401Live ||
      date162945_23_59_59Live ||
      date135945_1401Live;

    if (date162945_23_59_59Live) {
      // console.log("1630 True Reload");
    }

    if (!combineVarLive) {
      setTxData30Live(result30);

      // console.log("Should not enter");
      setBannerTransLive(result30);
    }

    let date930_931Live =
      nowDate > Moment(new Date(`${Moment().format("MM/DD/YYYY")} 09:30:01`)) &&
      nowDate < Moment(new Date(`${Moment().format("MM/DD/YYYY")} 09:31:00`));
    let date930_235959Live =
      nowDate > Moment(new Date(`${Moment().format("MM/DD/YYYY")} 9:30:00`)) &&
      nowDate < Moment(new Date(`${Moment().format("MM/DD/YYYY")} 23:59:59`));

    if (date930_931Live) {
      setBannerTransLive(result930);
    }
    if (date930_235959Live) {
      setTxData9_30Live(result930);
    }

    ///////
    let date1201_135959Live =
      nowDate > Moment(new Date(`${Moment().format("MM/DD/YYYY")} 12:01:00`)) &&
      nowDate < Moment(new Date(`${Moment().format("MM/DD/YYYY")} 12:30:00`));

    let date1201_235959Live =
      nowDate > Moment(new Date(`${Moment().format("MM/DD/YYYY")} 12:01:00`)) &&
      nowDate < Moment(new Date(`${Moment().format("MM/DD/YYYY")} 23:59:59`));

    if (date1201_135959Live) {
      setBannerTransLive(result1201);
    }
    if (date1201_235959Live) {
      setTxData12_01Live(result1201);
    }

    ///////
    let date1401_1410Live =
      nowDate > Moment(new Date(`${Moment().format("MM/DD/YYYY")} 14:00:01`)) &&
      nowDate < Moment(new Date(`${Moment().format("MM/DD/YYYY")} 14:01:00`));
    let date1401_235959Live =
      nowDate > Moment(new Date(`${Moment().format("MM/DD/YYYY")} 14:00:01`)) &&
      nowDate < Moment(new Date(`${Moment().format("MM/DD/YYYY")} 23:59:59`));

    if (date1401_1410Live) {
      setBannerTransLive(result1400);
    }
    if (date1401_235959Live) {
      setTxData14_00Live(result1400);
    }

    ////////

    let date16301_1700Live =
      nowDate > Moment(new Date(`${Moment().format("MM/DD/YYYY")} 16:30:01`)) &&
      nowDate < Moment(new Date(`${Moment().format("MM/DD/YYYY")} 17:00:00`));

    let date16301_235959Live =
      nowDate > Moment(new Date(`${Moment().format("MM/DD/YYYY")} 16:30:01`)) &&
      nowDate < Moment(new Date(`${Moment().format("MM/DD/YYYY")} 23:59:59`));

    if (date16301_1700Live) {
      setBannerTransLive(result1630);
    }

    if (date16301_235959Live) {
      setTxData4_30Live(result1630);
    }
  };

  const [value, setValue] = useState(
    Moment()
      .startOf("day")
      .add(1, "day")
      .set("hour", 4)
      .set("minute", 30)
      .valueOf() - Moment().valueOf()
  );
  const msToHours = (ms) => {
    const hours = Math.floor(ms / 1000 / 60 / 60);

    if (hours < 24) {
      return hours;
    }
  };

  var seconds = Moment.duration(value).seconds();
  var minutes = Moment.duration(value).minutes();
  var hours = msToHours(value);

  console.log(
    "txData30",
    txData30,
    "txData9_30",
    txData9_30,
    Moment(txData9_30?.tx_date).format("YYYY-MM-DD") ===
    Moment().format("YYYY-MM-DD")
  );

  return (
    <BrowserRouter>
      <Routes>
        <Route
          end
          path="/"
          element={
            <Home
              flashUpdate={flashUpdate}
              updateB={updateB}
              bannerTrans={bannerTrans}
              alltrans30={txData30}
              alltrans120={txData120}
              alltrans300={txData300}
              alltran9_30={txData9_30}
              alltran12_01={txData12_01}
              alltran14_00={txData14_00}
              alltran4_30={txData4_30}
              // lastData430={lastData430}
              // result430={result430}
              hours={hours}
              minutes={minutes}
              seconds={seconds}
            />
          }
        />
        <Route
          exact
          path="/live"
          element={
            <LivePage
              flashUpdate={flashUpdate}
              updateB={updateB}
              alltrans30={txData30Live}
              alltrans120={txData120Live}
              alltrans300={txData300Live}
              alltran9_30={txData9_30Live}
              alltran12_01={txData12_01Live}
              alltran14_00={txData14_00Live}
              alltran4_30={txData4_30Live}
              bannerTrans={bannerTransLive}
              hours={hours}
              minutes={minutes}
              seconds={seconds}
              lastTrans30={lastData30}
            />
          }
        />
        <Route
          exact
          path="/result"
          element={
            <Result
              alltrans={
                nowDate > date1
                  ? txData4_30
                  : nowDate > date3
                    ? txData12_01
                    : nowDate > date5
                      ? txData4_30
                      : txData12_01
              }
              hours={hours}
              minutes={minutes}
              seconds={seconds}
              type={
                nowDate > date1
                  ? "430PM"
                  : nowDate > date3
                    ? "1201PM"
                    : nowDate > date5
                      ? "430PM"
                      : "1201PM"
              }
            />
          }
        />
        <Route end path="/proof" element={<Proof alltrans={txData30} />} />
        <Route end path="/history" element={<History />} />
        <Route end path="/about" element={<About />} />
        <Route end path="/download" element={<Download />} />
      </Routes>
    </BrowserRouter>
  );
};

export default RouteAll;
